/* eslint-disable no-inner-declarations */
/* eslint-disable no-undef */
import './App.css';
import React, { useEffect, useState } from 'react';
import { useRoutes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import AuthGuard from 'app/auth/AuthGuard';
import scheduleRoutes from './views/schedule/ScheduleRoutes';
import adminRoutes from './views/admin/components/adminRoutes';
import healthRoutes from './views/health/healthRoutes';
import githubRoutes from './views/github/githubRoutes';
import settingsRoutes from './views/settings/SettingsRoutes';
import calendarRoutes from './views/calendar/CalendarRoutes';
import comparativesRoutes from './views/comparatives/comparativesRoutes';
import calendarRoutesMobile from './views/calendar/CalendarRoutesMobile';
import dashboardRoutes from './views/dashboard/DashboardRoutes';
import sentimentalRoutes from './views/sentimental/SentimentalRoutes';
import MatxLayout from './components/MatxLayout/MatxLayout';
import { useDispatch, useSelector } from 'react-redux';
import userActions from './redux/actions/userActions';
import { useAuth0 } from '@auth0/auth0-react';
import companyActions from 'app/redux/actions/companyActions';
import { Toaster } from 'react-hot-toast';
import toast from 'react-hot-toast';
import theme from 'app/styles/PaletteTheme';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import PublicCalendarShared from './components/PublicCalendarShared';
import TGAnalyticsRoutes from './views/timeGoalsAnalytics/TGAnalyticsRoutes';
import AnalyticsMobileRoutes from './views/mobile/AnalyticsMobileRoutes';
import TasksRoutes from './views/tasks/TasksRoutes';
import IntegrationsRoutes from './views/integrations/IntegrationsRoutes';
import TasksRoutesMobile from './views/tasks/TasksRoutesMobile';
import managerAnalyticsRoutes from './views/managerAnalytics/ManagerAnalyticsRoutes';
import connectionsRoutes from './views/manager/connections/connectionsRoutes';
import EmailsRoutes from './views/emails/EmailsRoutes';
import calendarActions from './redux/actions/calendarActions';
import taskActions from 'app/redux/actions/taskActions';
import projectActions from 'app/redux/actions/projectActions';
import emailMetricsActions from './redux/actions/emailMetricsActions';
import Confetti from './components/Confetti';
import EventInvitation from './components/EventInvitation';
import { useWindowWidth } from '@react-hook/window-size';
import managerActions from './redux/actions/managerActions';
import managerTeamsRoutes from './views/managerTeamsView/ManagerTeamsRoutes';
import postMeetingRoutes from './views/postMeeting/PostMeetingRoutes';
import usersAnalitycsRoutes from './views/managerUsersAnalitycs/ManagerUsersAnalitycs';
import teamActions from './redux/actions/teamActions';
import WeekResume from './components/WeekResume';
import patrasFeedbackRoutes from './views/feedback/PatrasFeedbackRoutes';
import managerCalendarsRoutes from './views/managerCalendar/ManagerCalendarsRoutes';
import FridayQuiz from './components/FridayQuiz';
import { getMonday, addDays } from 'app/utils/calendarUtils/DatesFunctions';
import scrumBoardRoutes from './views/scrum-board/ScrumBoardRoutes';
import recreationRoutes from './views/recreation/RecreationRoutes';
import regularScrumBoardRoutes from './views/regular-scrum-board/RegularScrumBoardRoutes';
import wordCloudRoutes from './views/managerCloudWords/ManagerWordCloudRoutes';
import SharedPdf from './components/SharedPdf';
import EmojiDialog from './components/EmojiDialog';
import IdleTimer from './utils/IdleTimer';

export const urlUserService = process.env.REACT_APP_URI_U_SERVICE;
export const urlArchivingService = process.env.REACT_APP_URI_ARCHIVING;

function App() {

  const onlyWidth = useWindowWidth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageByURL = useLocation().pathname.split('/')[1];
  const lastProfile = sessionStorage.getItem('profile');
  const publicCondition = (pageByURL === 'publicCalendarShared') || (pageByURL === 'eventInvitationShared')
  const userProfileSelection = useSelector((store) => store.userReducer.userProfile);
  const notiWeeklySummary = useSelector((store) => store.notificationsReducer.notiWeeklySummary);
  const notiFridayQuiz = useSelector((store) => store.notificationsReducer.notiFridayQuiz);
  const loggedUser = useSelector((store) => store.userReducer.user);
  const confetti = useSelector((store) => store.tasksReducer.confetti);
  const emojiDialog = useSelector((store) => store.tasksReducer.openEmojiDialog);
  const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const [res, setRes] = useState(null);
  const [personalView, setPersonalView] = useState(true);
  const [managerView, setManagerView] = useState(false);
  const [adminView, setAdminView] = useState(false);
  const [openResume, setOpenResume] = useState(false);
  const [openQuiz, setOpenQuiz] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const mondayDay = getMonday(new Date());
  const nextMonday = addDays(7, new Date(mondayDay));
  const [loginMaded, setLoginMaded] = useState(false);
  const [openEmojiDialog, setOpenEmojiDialog] = useState(false);

  const setAuthToken = (token) => dispatch({
    type: 'SET_AUTH_TOKEN',
    token
  });

  useEffect(() => {
    if (user) {
      async function waitResponse() {
        const token = await getAccessTokenSilently();
        setAuthToken(token);
        const metadata = await getIdTokenClaims();
        dispatch(calendarActions.getCalendarView(metadata.api_metadata.calendarView ?
          metadata.api_metadata.calendarView : 'calendar'));
        dispatch(userActions.managerTableSort(metadata.api_metadata.managerTableView ?
          metadata.api_metadata.managerTableView : ['meetTime', false]));
        dispatch(userActions.peopleTableSort(metadata.api_metadata.peopleTableSort ?
          metadata.api_metadata.peopleTableSort : ['name', false]));
        dispatch(userActions.teamsTableSort(metadata.api_metadata.teamsTableSort ?
          metadata.api_metadata.teamsTableSort : ['teamName', false]));
        dispatch(userActions.emailDataTableSort(metadata.api_metadata.emailDataTableView ?
          metadata.api_metadata.emailDataTableView : ['interaction', false]));
        dispatch(userActions.userRetroBoardTableSort(metadata.api_metadata.regularRetroBoardTableView ?
          metadata.api_metadata.regularRetroBoardTableView : ['date', false]));
        dispatch(taskActions.sortChoice(metadata.api_metadata.taskTableView ?
          metadata.api_metadata.taskTableView : ['date', true]));
        dispatch(taskActions.getTaskView(metadata.api_metadata.taskView ?
          metadata.api_metadata.taskView : 'table'));
        const response = await dispatch(userActions.getUser(user.email));
        if (response?.data) {
          setLoginMaded(true);
        }
        setRes(response);
      }
      waitResponse();
    }
    //eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (loggedUser) {
      dispatch(companyActions.getCompany(loggedUser?.User.CompanyId));
      dispatch(calendarActions.getTGAnalitycs({ userId: loggedUser?.UserId, from: mondayDay, till: nextMonday }))
      dispatch(calendarActions.getUserAllEvents(loggedUser?.UserId));
      dispatch(calendarActions.getUserCalendars(loggedUser?.UserId)).then(res => {
        if (res.data) {
          const calendarPrimary = res.data.response.calendars?.find((calendar) => calendar.calendarType === 'primary');
          dispatch(calendarActions.getWorkingHours(calendarPrimary?.id));
        }
      });
      dispatch(taskActions.getTasksByUser(loggedUser?.UserId, 'completed')).then(async res => {
        if (res.data) {
          let tasksWithClickUpStatuses = await Promise.all(
            res.data.response?.filter(task => task.status === true).map(async (task) => {
              let taskStatus;
              if (task.projectType === 'ClickUp') {
                const res = await dispatch(taskActions.getClickUpStatuses(task.id));
                if (res.data) {
                  taskStatus = res.data.response;
                }
              }
              return {
                id: task.id,
                clickUpTaskStatus: taskStatus,
              };
            })
          );
          if (tasksWithClickUpStatuses.length > 0) {
            dispatch(taskActions.tasksWithClickUpStatuses(tasksWithClickUpStatuses));
          }
        }
      });
      dispatch(projectActions.getProjectsByUser(loggedUser?.UserId));
      dispatch(emailMetricsActions.getEmailsByUser(loggedUser?.UserId)).then((res) => {
        if (res.data) {
          const emailId = res.data.response[0].id;
          dispatch(emailMetricsActions.synchronizeEmailData(emailId));
        }
      });
      dispatch(managerActions.getCompanyData());
      dispatch(teamActions.getTeam(loggedUser?.User?.TeamId));
      dispatch(taskActions.getTaskIntegrations());
      dispatch(emailMetricsActions.getEMIntegrations());
      dispatch(teamActions.getTeamByCompany());
    }
    if (loggedUser && user.email_verified) {
      toast.success(`Bienvenido/a ${loggedUser?.User.firstName}`);
    }
    //eslint-disable-next-line
  }, [loggedUser]);

  const navigateAndClear = () => {
    navigate(`${pageByURL}`);
    sessionStorage.removeItem('profile');
    setFirstRender(false);
  };

  useEffect(() => {
    if (!publicCondition && userProfileSelection === 'personal' && !personalView) {
      (lastProfile && firstRender) ? navigateAndClear() : navigate('/index');
      setPersonalView(true);
      setManagerView(false);
      setAdminView(false);
    }
    if (!publicCondition && userProfileSelection === 'manager' && !managerView) {
      (lastProfile && firstRender) ? navigateAndClear() : navigate('/managerDashboard');
      dispatch(managerActions.registerManagerAccess());
      setPersonalView(false);
      setManagerView(true);
      setAdminView(false);
    }
    if (!publicCondition && userProfileSelection === 'admin' && !adminView) {
      (lastProfile && firstRender) ? navigateAndClear() : navigate('/people');
      setPersonalView(false);
      setManagerView(false);
      setAdminView(true);
    }
    // eslint-disable-next-line
  }, [userProfileSelection]);

  useEffect(() => {
    if (notiWeeklySummary) {
      setOpenResume(true);
    }
    // eslint-disable-next-line
  }, [notiWeeklySummary]);

  useEffect(() => {
    if (notiFridayQuiz && new Date().getDay() === 5) {
      setOpenQuiz(true);
    }
    // eslint-disable-next-line
  }, [notiFridayQuiz]);

  useEffect(() => {
    if (emojiDialog) {
      setOpenEmojiDialog(true);
    } else {
      setOpenEmojiDialog(false);
    }
    // eslint-disable-next-line
  }, [emojiDialog]);

  useEffect(() => {
    if (emojiDialog) {
      setOpenEmojiDialog(true);
    } else {
      setOpenEmojiDialog(false);
    }
    // eslint-disable-next-line
  }, [emojiDialog]);

  const routes =
    [
      {
        element: (
          <ThemeProvider theme={theme}>
            <AuthGuard res={res} loginMaded={loginMaded} setLoginMaded={setLoginMaded} >
              <MatxLayout />
            </AuthGuard>
          </ThemeProvider >
        ),
        children: loggedUser?.User.role === 'regular' ?
          ((onlyWidth > 800) ? [
            ...dashboardRoutes,
            ...comparativesRoutes,
            ...healthRoutes,
            ...githubRoutes,
            ...scheduleRoutes,
            ...settingsRoutes,
            ...calendarRoutes,
            ...TGAnalyticsRoutes,
            ...TasksRoutes,
            ...IntegrationsRoutes,
            ...EmailsRoutes,
            ...postMeetingRoutes,
            ...patrasFeedbackRoutes,
            ...recreationRoutes,
            ...regularScrumBoardRoutes
          ] : [
            ...calendarRoutesMobile,
            ...comparativesRoutes,
            ...healthRoutes,
            ...githubRoutes,
            ...AnalyticsMobileRoutes,
            ...TGAnalyticsRoutes,
            ...settingsRoutes,
            ...TasksRoutesMobile,
            ...IntegrationsRoutes,
            ...EmailsRoutes,
          ])
          : loggedUser?.User.role === 'admin' ?
            ((onlyWidth > 800) ? [
              ...dashboardRoutes,
              ...comparativesRoutes,
              ...healthRoutes,
              ...githubRoutes,
              ...scheduleRoutes,
              ...settingsRoutes,
              ...calendarRoutes,
              ...TGAnalyticsRoutes,
              ...TasksRoutes,
              ...IntegrationsRoutes,
              ...EmailsRoutes,
              ...postMeetingRoutes,
              ...patrasFeedbackRoutes,
              ...recreationRoutes,
              ...adminRoutes,
              ...regularScrumBoardRoutes
            ] : [
              ...calendarRoutesMobile,
              ...comparativesRoutes,
              ...healthRoutes,
              ...githubRoutes,
              ...AnalyticsMobileRoutes,
              ...TGAnalyticsRoutes,
              ...settingsRoutes,
              ...TasksRoutesMobile,
              ...IntegrationsRoutes,
              ...EmailsRoutes,
              ...adminRoutes
            ])
            :
            (onlyWidth > 800) ? [
              ...dashboardRoutes,
              ...comparativesRoutes,
              ...scheduleRoutes,
              ...calendarRoutes,
              ...TGAnalyticsRoutes,
              ...TasksRoutes,
              ...IntegrationsRoutes,
              ...EmailsRoutes,
              ...postMeetingRoutes,
              ...managerAnalyticsRoutes,
              ...usersAnalitycsRoutes,
              ...managerCalendarsRoutes,
              ...connectionsRoutes,
              ...wordCloudRoutes,
              ...sentimentalRoutes,
              ...healthRoutes,
              ...githubRoutes,
              ...settingsRoutes,
              ...managerTeamsRoutes,
              ...patrasFeedbackRoutes,
              ...scrumBoardRoutes,
              ...recreationRoutes,
              ...adminRoutes,
              ...regularScrumBoardRoutes
            ] : [
              ...calendarRoutesMobile,
              ...comparativesRoutes,
              ...AnalyticsMobileRoutes,
              ...TGAnalyticsRoutes,
              ...TasksRoutesMobile,
              ...IntegrationsRoutes,
              ...EmailsRoutes,
              ...managerCalendarsRoutes,
              ...managerAnalyticsRoutes,
              ...usersAnalitycsRoutes,
              ...connectionsRoutes,
              ...wordCloudRoutes,
              ...healthRoutes,
              ...githubRoutes,
              ...settingsRoutes,
              ...sentimentalRoutes,
              ...managerTeamsRoutes,
              ...adminRoutes,
            ]

      },
      {
        path: '/publicCalendarShared/:calendarShareId',
        element: (
          <ThemeProvider theme={theme}>
            <PublicCalendarShared />
          </ThemeProvider>
        )
      },
      {
        path: '/eventInvitationShared/:eventShareId',
        element: (
          <ThemeProvider theme={theme}>
            <EventInvitation />
          </ThemeProvider>
        )
      },
      {
        path: '/sharedPdf/:pdfId',
        element: (
          <ThemeProvider theme={theme}>
            <SharedPdf />
          </ThemeProvider>
        )
      },
      {
        path: '/invitationLink/:invitationLink', element: <ThemeProvider theme={theme}>
          <AuthGuard res={res} loginMaded={loginMaded} setLoginMaded={setLoginMaded} >
            <MatxLayout />
          </AuthGuard>
        </ThemeProvider >
      },
      { path: '/', element: <Navigate to="index" /> },
      { path: '*', element: <Navigate to="index" /> }
    ];

  const content = useRoutes(routes);

  return (
    <>
      <div className="App">
        {confetti && <Confetti />}
        <LocalizationProvider dateAdapter={AdapterMoment}>{content}</LocalizationProvider>
        <WeekResume openResume={openResume} closeResume={() => setOpenResume(false)} />
        <FridayQuiz openQuiz={openQuiz} closeQuiz={() => setOpenQuiz(false)} />
        <EmojiDialog openEmojiDialog={openEmojiDialog} />
        <Toaster
          toastOptions={{
            className: '',
            style: {
              boxShadow: '0px 3px 10px rgba(8, 8, 8, 0.413)',
              padding: '.8rem',
              color: 'black',
              textAlign: 'center',
              fontSize: '1rem'
            }
          }}
        />
        <IdleTimer />

      </div>
    </>
  );
}

export default App;
