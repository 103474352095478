import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Icon,
  Typography,
  TextField,
  styled,
  Dialog,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import '../styles/PublicCalendarShared.css';
import dayjs from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import publicActions from 'app/redux/actions/publicActions';
import { useDispatch, useSelector } from 'react-redux';
import { useErrorHandler } from 'react-error-boundary';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import logo from '../../patrasLogos/logo-negro-color.png';
import iconCheck from '../utils/iconsImages/task_alt_green.png';
import live_tv from '../utils/iconsImages/live_tv.png';
import { useWindowWidth } from '@react-hook/window-size';
import { PrimaryButton } from 'app/utils/ButtonsPatras';
import { CalendarMonthIcon } from 'app/utils/svgIcons';
import { FlexJustifyCenter } from './FlexBox';
import calendarActions from 'app/redux/actions/calendarActions';
import MatxLoadingCustom from './MatxLoadindCustom';
import _ from 'lodash';

dayjs.extend(isBetweenPlugin);

const Image = styled('img')(() => ({
  width: '18rem',
  padding: '2rem'
}));

const TaskAlt = styled('img')(() => ({
  width: '25px',
  marginRight: '.8rem'
}));

const IconTV = styled('img')(() => ({
  width: '25px'
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  borderRadius: 2,
  width: '100%',
  height: '100%',
  '& .css-1huol00-MuiPaper-root-MuiDialog-paper': {
    maxWidth: '100% !important'
  },
  '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
    maxWidth: 'none'
  }
};

function PublicCalendarShared() {

  const onlyWidth = useWindowWidth();
  const dispatch = useDispatch();
  const handleError = useErrorHandler();
  const { calendarShareId } = useParams();
  const loggedUser = useSelector((store) => store.userReducer.user);
  const [loading, setLoading] = useState(false);
  const [matchesReady, setMatchesReady] = useState(false);
  const [searchMatchAv, setSearchMatchAv] = useState(true);
  const today = new Date();
  const [lastDay, setLastDay] = useState(new Date());
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = React.useState(dayjs(today));
  const [timeSelected, setTimeSelected] = useState(null);
  const [meetingDay, setMeetingDay] = useState(null);
  const [guestEmail, setGuestEmail] = useState(null);
  const [meetingConfirmation, setMeetingConfirmation] = useState(false);
  const [modalEmail, setModalEmail] = useState(false);
  const [availabilityTime, setAvailabilityTime] = useState(null);
  const [userAvailabilityTime, setUserAvailabilityTime] = useState(null);
  const [availableTime, setAvailableTime] = useState([]);
  let arrayBusyTime = [];
  let fullAvTime = [];
  let time = new Date(availabilityTime?.data.startWorkDay);
  let endTime = new Date(availabilityTime?.data.endWorkDay);
  let endTimeMinute = new Date(availabilityTime?.data.endWorkDay);
  //let workingDays = availabilityTime?.workingDays;
  let nextTime = `${time.getHours() < 10 ? `0${time.getHours()}` : time.getHours()}:${time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes()
    }`;
  let lastTime = `${endTime.getHours() < 10 ? `0${endTime.getHours()}` : endTime.getHours()}:${endTime.getMinutes() === 0 ? '00' : endTime.getMinutes()
    }`;
  let allTimes = [nextTime];
  const [reload, setReload] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [posibleEndMeeting, setPosibleEndMeeting] = useState(null);
  const [countDown, setCountDown] = useState(null);
  const [start, setStart] = useState(value.startOf('day'));
  const end = dayjs(lastDay);
  const [allWeekAvailableTime, setAllWeekAvailableTime] = useState([]);
  const [userAllWeekAvailableTime, setUserAllWeekAvailableTime] = useState([]);
  let allWeekArray = [];

  useEffect(() => {
    dispatch(publicActions.getPublicCalendarAvailability(calendarShareId)).then((res) => {
      if (!res.data) {
        handleError(res);
      } else {
        setAvailabilityTime(res.data.response);
      }
    });

    if (loggedUser) {
      dispatch(calendarActions.getMyBusyTime(loggedUser?.UserId)).then((res) => {
        if (!res.data) {
          handleError(res);
        } else {
          setUserAvailabilityTime(res.data.response);
        }
      });
    }
    // eslint-disable-next-line
  }, [reload, meetingDay, loggedUser]);

  useEffect(() => {
    if (nextTime !== 'NaN:NaN') {
      if (firstRender) {
        allWeekAvailability(value, availabilityTime, 'guest');
        setFirstRender(false);
      }
    }
    //eslint-disable-next-line
  }, [nextTime])

  const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) =>
      prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay' && prop !== 'thisDay'
  })(({ theme, saturday, sunday, dayIsBetween, thisDay, isFirstDay, isLastDay, isPast, isAMonthAHead }) => ({
    ...(dayIsBetween && {
      backgroundColor: '#C62F4A !important',
      borderRadius: 0,
      color: '#FFFFFF !important',
      '&:hover, &:focus': {
        backgroundColor: '#C62F4A',
        color: '#FFFFFF'
      }
    }),
    ...(dayIsBetween &&
      (sunday || saturday) && {
      borderRadius: 0,
      backgroundColor: 'transparent !important',
      color: '#33314C',
      pointerEvents: 'none',
      '&:hover, &:focus': {
        backgroundColor: theme.palette.text.primary
      }
    }),
    ...((isPast || isAMonthAHead || sunday || saturday) && {
      pointerEvents: 'none'
    }),
    ...(thisDay && {
      backgroundColor: '#C62F4A !important',
      borderRadius: 50,
      color: 'white !important',
    }),
    ...(isFirstDay && {
      borderTopLeftRadius: '50% !important',
      borderBottomLeftRadius: '50% !important',
      borderRadius: 0,
      backgroundColor: '#C62F4A !important',
      color: 'white !important',
    }),
    ...(isLastDay && {
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%'
    })
  }));

  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!value) {
      return <PickersDay disabled={true} {...pickersDayProps} />;
    }

    const saturday = date.$W === 6;
    const sunday = date.$W === 0;
    const dayIsBetween = date.isBetween(start, end, null, '[]');
    const thisDay = new Date(date.$d).toLocaleDateString() == new Date().toLocaleDateString();
    const isFirstDay = date.isSame(start, 'day');
    const isLastDay = date.isSame(end, 'day');
    const isPast = new Date(date.$d) < new Date().setDate(new Date().getDate() - 1);
    const isAMonthAHead = new Date(date.$d) > new Date().setDate(new Date().getDate() + 30);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        saturday={saturday}
        sunday={sunday}
        dayIsBetween={dayIsBetween}
        today={thisDay}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
        isPast={isPast}
        isAMonthAHead={isAMonthAHead}
      />
    );
  };

  const handleTimeChoice = (time, index) => {
    let posibleEndMeeting = new Date();
    posibleEndMeeting.setHours(time.split(':')[0]);
    posibleEndMeeting.setMinutes(time.split(':')[1]);
    posibleEndMeeting.setTime(
      posibleEndMeeting.getTime() + availabilityTime?.data.duration * 60 * 1000
    );
    let posibleEndTimeMeet = `${posibleEndMeeting.getHours() < 10
      ? `0${posibleEndMeeting.getHours()}`
      : posibleEndMeeting.getHours()
      }:${posibleEndMeeting.getMinutes() < 10
        ? `0${posibleEndMeeting.getMinutes()}`
        : posibleEndMeeting.getMinutes()
      }`;
    if (meetingDay) {
      setMeetingDay(allWeekAvailableTime[index]);
      setTimeSelected(time);
      setPosibleEndMeeting(posibleEndTimeMeet);
      setReload(!reload);
    } else {
      setMeetingDay(allWeekAvailableTime[index]);
      toast.error('Seleccione primero una fecha');
    }
  };

  const handleConfirmation = () => {
    if (meetingDay && timeSelected) {
      setModalEmail(true);
    } else {
      toast.error('Debe seleccionar un día y horario');
    }
  };

  useEffect(() => {
    if (countDown !== null) {
      countDown > 0
        ? _.delay(() => {
          setCountDown(prevCountDown => prevCountDown - 1);
        }, 1000)
        : window.location.replace('https://www.patras.io');
    }
    //eslint-disable-next-line
  }, [countDown]);

  const meetingCreated = () => {
    setLoading(true);
    // eslint-disable-next-line
    let re = /^([\da-z_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/;
    const finishTime = posibleEndMeeting;
    let dateStart = new Date(meetingDay?.$d);
    dateStart.setHours(timeSelected.split(':')[0]);
    dateStart.setMinutes(timeSelected.split(':')[1]);
    let dateEnd = new Date(meetingDay.$d);
    dateEnd.setHours(finishTime?.split(':')[0]);
    dateEnd.setMinutes(finishTime?.split(':')[1]);
    if (guestEmail && re.exec(guestEmail)) {
      dispatch(
        publicActions.createPublicEvent(
          calendarShareId,
          dateStart.toISOString(),
          dateEnd.toISOString(),
          [guestEmail]
        )
      ).then((res) => {
        if (!res.data) {
          handleError(res);
        } else {
          setMeetingConfirmation(true);
          setLoading(false);
          setCountDown(5);
        }
      });
    } else {
      toast.error('Por favor ingrese un e-mail válido');
      setLoading(false);
    }
  };

  const handleDateChoice = (newValue, availability = availabilityTime, person = 'guest') => {
    const startTimeInHours = new Date(availabilityTime?.data.startWorkDay).getHours();
    const startTimeInMinutes = new Date(availabilityTime?.data.startWorkDay).getMinutes();
    let time = new Date(new Date(newValue.$d).setHours(startTimeInHours, startTimeInMinutes));
    let nextTime = `${time.getHours() < 10 ? `0${time.getHours()}` : time.getHours()}:${time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes()
      }`;
    allTimes = [nextTime];
    arrayBusyTime = [];
    fullAvTime = [];
    setMeetingDay(newValue);
    const timeEvents = availability?.busyTimes.filter(
      (bt) =>
        new Date(bt.start).toLocaleDateString() === new Date(newValue?.$d).toLocaleDateString()
    );

    const busyTimeCreater = () => {
      timeEvents?.forEach((event) => {
        let time = new Date(event.start);
        let endTime = new Date(event.end);
        let nextTime = `${time.getHours() < 10 ? `0${time.getHours()}` : time.getHours()}:${time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes()
          }`;
        let lastTime = `${endTime.getHours() < 10 ? `0${endTime.getHours()}` : endTime.getHours()
          }:${endTime.getMinutes() === 0 ? '00' : endTime.getMinutes()}`;
        arrayBusyTime.push(
          `${time.getHours() < 10 ? `0${time.getHours()}` : time.getHours()}:${time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes()
          }`
        );
        while (nextTime < lastTime) {
          time.setTime(time.getTime() + 1 * 60 * 1000);
          nextTime = `${time.getHours() < 10 ? `0${time.getHours()}` : time.getHours()}:${time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes()
            }`;
          arrayBusyTime.push(nextTime);
        }
      });
      let lastAvTime = endTimeMinute.setTime(endTimeMinute.getTime() + 60 * 1000);
      lastAvTime = `${endTimeMinute.getHours() < 10 ? `0${endTimeMinute.getHours()}` : endTimeMinute.getHours()
        }:${endTimeMinute.getMinutes() < 10
          ? `0${endTimeMinute.getMinutes()}`
          : endTimeMinute.getMinutes()
        }`;
      arrayBusyTime.push(lastAvTime);
    };

    busyTimeCreater();

    const avTimeCreater = () => {
      let today = new Date();
      if (today.toLocaleDateString() === new Date(newValue?.$d).toLocaleDateString()) {
        if (today.getMinutes() > 30) {
          time.setHours(today.getHours() + 1);
          time.setTime(time.getTime() + 30 * 60 * 1000);
        } else {
          time.setHours(today.getHours() + 1);
        }
        nextTime = `${time.getHours() < 10 ? `0${time.getHours()}` : time.getHours()}:${time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes()
          }`;
        if (time.getHours() > 9) {
          allTimes.push(nextTime);
          allTimes.shift();
        }
      }
      while (nextTime < lastTime) {
        time.setTime(time.getTime() + 30 * 60 * 1000);
        nextTime = `${time.getHours() < 10 ? `0${time.getHours()}` : time.getHours()}:${time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes()
          }`;
        allTimes.push(nextTime);
      }
    };

    avTimeCreater();

    let timeFutureMeet = [];

    const fullAvTimeCreator = () => {
      let timeMeeting = new Date();
      let endMeet = new Date();
      allTimes.forEach((avTime) => {
        timeMeeting.setHours(avTime.split(':')[0]);
        timeMeeting.setMinutes(avTime.split(':')[1]);
        endMeet.setHours(avTime.split(':')[0]);
        endMeet.setMinutes(avTime.split(':')[1]);
        let startFutureMeet = `${timeMeeting.getHours() < 10 ? `0${timeMeeting.getHours()}` : timeMeeting.getHours()
          }:${timeMeeting.getMinutes() < 10 ? `0${timeMeeting.getMinutes()}` : timeMeeting.getMinutes()
          }`;
        let endFutureMeet = endMeet.setTime(
          endMeet.getTime() + (availabilityTime?.data.duration - 1) * 60 * 1000
        );
        endFutureMeet = `${endMeet.getHours() < 10 ? `0${endMeet.getHours()}` : endMeet.getHours()
          }:${endMeet.getMinutes() < 10 ? `0${endMeet.getMinutes()}` : endMeet.getMinutes()}`;

        while (startFutureMeet < endFutureMeet) {
          timeMeeting.setTime(timeMeeting.getTime() + 1 * 60 * 1000);
          startFutureMeet = `${timeMeeting.getHours() < 10 ? `0${timeMeeting.getHours()}` : timeMeeting.getHours()
            }:${timeMeeting.getMinutes() < 10
              ? `0${timeMeeting.getMinutes()}`
              : timeMeeting.getMinutes()
            }`;
          timeFutureMeet.push(startFutureMeet);
        }

        const fullAvTimeCondition = timeFutureMeet.every((element) => {
          return !arrayBusyTime.includes(element);
        });

        if (fullAvTimeCondition) {
          fullAvTime.push(avTime);
        }

        timeFutureMeet = [];
      });
    };

    fullAvTimeCreator();
    let dayWithTimes = {
      $d: newValue.$d,
      availablesTimes: fullAvTime
    }

    if (person === 'guest') {
      allWeekArray.push(dayWithTimes);
      setAllWeekAvailableTime(allWeekArray);
      setAvailableTime(fullAvTime);
    }

    if (person === 'user') {
      allWeekArray.push(dayWithTimes);
      setUserAllWeekAvailableTime(allWeekArray);
    }

    setReload(!reload);
  };


  useEffect(() => {
    setStart(new Date(allWeekAvailableTime[0]?.$d));
    setLastDay(new Date(allWeekAvailableTime[allWeekAvailableTime.length - 1]?.$d));
    // eslint-disable-next-line
  }, [allWeekAvailableTime])

  const allWeekAvailability = (newValue, availability = availabilityTime, person) => {
    for (let index = 0; index < 5; index++) {
      if (availability) {
        handleDateChoice(newValue, availability, person);
        const weekDay = new Date(newValue.$d).getDay();
        let addDay = new Date(new Date(newValue.$d).setDate(new Date(newValue.$d).getDate() + (weekDay === 5 ? 3 : weekDay === 6 ? 2 : 1)));
        newValue = { $d: addDay }
      }
    }
  };

  const handleAutomaticSearch = () => {
    setMatchesReady(true);
    setSearchMatchAv(false);
    allWeekAvailability(value, userAvailabilityTime, 'user');
  };

  useEffect(() => {
    if (allWeekAvailableTime.length !== 0 && userAllWeekAvailableTime.length !== 0) {
      findMatchingTimes(allWeekAvailableTime, userAllWeekAvailableTime);
    }
    //eslint-disable-next-line
  }, [userAllWeekAvailableTime])


  function findMatchingTimes(guestAvTimes, userAvTimes) {
    const allWeekMatches = [];
    for (let i = 0; i < guestAvTimes.length; i++) {
      const commonTimesByDay = [];
      for (let j = 0; j < guestAvTimes[i]?.availablesTimes.length; j++) {
        if (userAvTimes[i]?.availablesTimes.includes(guestAvTimes[i]?.availablesTimes[j])) {
          commonTimesByDay.push(guestAvTimes[i]?.availablesTimes[j]);
        }
      }
      let matchesDay = {
        $d: guestAvTimes[i].$d,
        availablesTimes: commonTimesByDay.length ? commonTimesByDay : []
      }
      allWeekMatches.push(matchesDay);
    }
    setAllWeekAvailableTime(allWeekMatches);
    setMatchesReady(false);
  }


  return (
    <Box className="bodyPublicCalendar">
      {
        onlyWidth > 1000 ?
          <Box sx={{ width: '95% !important' }} className="publicCalendarShared-container">
            <Box className="mainDate-container">
              <Box
                sx={{
                  display: 'flex',
                  mt: 4,
                  alignItems: 'center',
                  justifyContent: 'space-around',
                }}
              >
                <Typography sx={{ fontSize: '1.2rem' }}>Programar una reunión</Typography>
                <Box>
                  <Typography sx={{ fontSize: '1rem', textAlign: 'start' }}>
                    {availabilityTime?.data.ownerName}
                  </Typography>
                  <Typography
                    sx={{ fontSize: '2rem', fontWeight: '500', textAlign: 'start' }}
                  >
                    {availabilityTime?.data.eventName ? availabilityTime?.data.eventName : 'Meeting'}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px' }}>
                  <Icon
                    sx={{
                      backgroundColor: '#FAFAFA',
                      color: '#606161',
                      width: '40px',
                      height: '40px',
                      borderRadius: '6px',
                      marginRight: '8px',
                      paddingTop: '5px',
                      fontSize: '1.8rem !important'
                    }}
                  >
                    access_time
                  </Icon>
                  <Typography className="subInfoMeet" sx={{ fontSize: '1.2rem', color: '#606161' }}>
                    {availabilityTime?.data.duration} min de duración
                  </Typography>
                </Box>
                {availabilityTime && availabilityTime.data.conference ? (
                  availabilityTime.data.Calendar.provider === 'google-oauth2' ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Box
                        sx={{
                          backgroundColor: '#FAFAFA',
                          width: '40px',
                          height: '40px',
                          borderRadius: '6px',
                          marginRight: '8px',
                          fontSize: '1.8rem !important'
                        }}
                      >
                        <IconTV sx={{ margin: 'auto' }} src={live_tv} />
                      </Box>
                      <Typography sx={{ fontSize: '1.2rem', color: '#606161' }}>
                        Google Meet
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Box
                        sx={{
                          backgroundColor: '#FAFAFA',
                          width: '40px',
                          height: '40px',
                          borderRadius: '6px',
                          marginRight: '8px',
                          fontSize: '1.8rem !important'
                        }}
                      >
                        <IconTV sx={{ margin: 'auto' }} src={live_tv} />
                      </Box>
                      <Typography sx={{ fontSize: '1.2rem', color: '#606161' }}>
                        /Microsoft Teams/Skype
                      </Typography>
                    </Box>
                  )
                ) : (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Icon
                      sx={{
                        backgroundColor: '#FAFAFA',
                        color: '#606161',
                        width: '40px',
                        height: '40px',
                        borderRadius: '6px',
                        marginRight: '8px',
                        paddingTop: '5px',
                        fontSize: '1.8rem !important'
                      }}
                    >
                      room
                    </Icon>
                    <Typography sx={{ fontSize: '1.2rem', color: '#606161' }}>
                      {availabilityTime?.data.location
                        ? availabilityTime.data.location
                        : 'A convenir'}
                    </Typography>
                  </Box>
                )}
                {meetingDay && timeSelected && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                    <Typography className="titleDateSelected">Fecha seleccionada</Typography>
                    <Typography
                      className="infoDateSelected"
                      sx={{ color: '#FA3E6A', fontSize: '22px', fontWeight: 600 }}
                    >
                      {new Date(meetingDay.$d).toLocaleDateString('es-AR', {
                        month: 'long',
                        day: 'numeric'
                      })}
                      , {timeSelected} hs
                    </Typography>
                  </Box>
                )}
                {meetingDay && timeSelected ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: '#C23750',
                      padding: '10px 25px',
                      borderRadius: '4px',
                      cursor: 'pointer'
                    }}
                  >
                    <Icon sx={{ color: 'white', marginRight: '8px' }}>date_range</Icon>
                    <Typography
                      sx={{ textTransform: 'uppercase', fontSize: '14px', color: 'white' }}
                      onClick={handleConfirmation}
                    >
                      Guardar reunión
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: '#F5F5F5',
                      padding: '10px 25px',
                      borderRadius: '4px'
                    }}
                  >
                    <Icon sx={{ color: '#BEBDBD', marginRight: '8px' }}>date_range</Icon>
                    <Typography
                      sx={{ textTransform: 'uppercase', fontSize: '14px', color: '#BEBDBD' }}
                      onClick={handleConfirmation}
                    >
                      Guardar reunión
                    </Typography>
                  </Box>
                )}
              </Box>
              {
                matchesReady ?
                  <MatxLoadingCustom text={'Buscando coincidencias entre los dos calendarios'} />
                  :
                  <Box sx={{ display: 'flex', justifyContent: 'space-around', height: '100%', mt: 10 }}>
                    <Box>
                      <Typography className="titleInstruction">Elegir el día</Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <StaticDatePicker
                          displayStaticWrapperAs="desktop"
                          label="Week picker"
                          value={value}
                          onChange={(newValue) => {
                            allWeekAvailability(newValue, availabilityTime, 'guest');
                            setSearchMatchAv(true);
                            setValue(newValue);
                          }}
                          renderDay={renderWeekPickerDay}
                          renderInput={(params) => <TextField {...params} />}
                          inputFormat="'Week of' MMM d"
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box sx={{ width: '70%' }}>
                      <Box sx={{ display: 'flex', height: '100% !important', position: 'relative' }}>
                        {
                          allWeekAvailableTime.length !== 0 && allWeekAvailableTime.map((day, index) => (
                            <Box key={index} className="box-times">
                              <Typography sx={{ textTransform: 'capitalize', textAlign: 'start', ml: 2, position: 'sticky', top: '0%', zIndex: 10, backgroundColor: 'white', padding: '0px 0px 10px 5px' }}>{new Date(day.$d).toLocaleDateString('es-AR', { weekday: 'long', day: '2-digit', month: '2-digit' })}</Typography>
                              <FormControl
                                sx={{ width: '90%' }}
                                component="fieldset"
                                className="formControl"
                              >
                                <RadioGroup
                                  name="emailService"
                                  className="group"
                                  aria-label="Email"
                                  sx={{ backgroundColor: '' }}
                                  onChange={(e) => handleTimeChoice(e.target.value, index)}
                                >
                                  {day.length !== 0 &&
                                    day?.availablesTimes.map((time, index) =>
                                      ((timeSelected === time) &&
                                        (new Date(day.$d).toLocaleDateString('es-AR', { day: '2-digit', month: '2-digit' }) === new Date(meetingDay.$d).toLocaleDateString('es-AR', { day: '2-digit', month: '2-digit' }))) ? (
                                        <FormControlLabel
                                          sx={{
                                            backgroundColor: '#FFF1F4',
                                            opacity: '100%',
                                            width: '100%',
                                            justifyContent: 'space-between',
                                            padding: '.5rem .8rem',
                                            margin: '.3rem'
                                          }}
                                          labelPlacement="start"
                                          value={time}
                                          control={
                                            <Radio
                                              sx={{
                                                color: '#f7d2d2',
                                                '&.Mui-checked': {
                                                  color: '#C23750'
                                                }
                                              }}
                                            />
                                          }
                                          label={time}
                                          key={index}
                                        />
                                      ) : (
                                        <FormControlLabel
                                          checked={false}
                                          sx={{
                                            backgroundColor: '#FAFAFA',
                                            width: '100%',
                                            justifyContent: 'space-between',
                                            padding: '.5rem .8rem',
                                            margin: '.3rem'
                                          }}
                                          labelPlacement="start"
                                          value={time}
                                          control={<Radio />}
                                          label={time}
                                          key={index}
                                        />
                                      )
                                    )}
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          ))
                        }
                      </Box>
                    </Box>
                  </Box>
              }
            </Box>
            <Dialog
              open={modalEmail}
              onClose={() => {
                setModalEmail(false);
              }}
              aria-labelledby="form-dialog-title"
              sx={{ ...style }}
            >
              <Box
                className="meetConfirmation-container"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                  justifyContent: 'space-between',
                  padding: '26px',
                  height: '25rem',
                  width: '35rem'
                }}
              >
                {meetingConfirmation ? (
                  <Typography
                    sx={{ fontSize: '22px', fontWeight: '600', marginBottom: '1rem' }}
                    id="form-dialog-title"
                  >
                    Reunión confirmada
                  </Typography>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                      marginBottom: '.5rem'
                    }}
                  >
                    <Typography sx={{ fontSize: '22px', fontWeight: '600' }} id="form-dialog-title">
                      ¿Confirmar reunión?
                    </Typography>
                    <Icon
                      onClick={() => setModalEmail(false)}
                      sx={{ fontWeight: '600 !important', cursor: 'pointer' }}
                    >
                      close
                    </Icon>
                  </Box>
                )}
                <Box sx={{ width: '100%' }}>
                  <Box
                    className="infoMeetConfirmation"
                    sx={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between' }}
                  >
                    <Box
                      sx={{
                        gap: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Typography sx={{ fontSize: '15px', color: '#606161' }}>
                        {availabilityTime?.data.ownerName}
                      </Typography>
                      <Typography sx={{ fontSize: '22px', fontWeight: '600' }}>
                        {availabilityTime?.data.eventName
                          ? availabilityTime?.data.eventName
                          : 'Meeting'}
                      </Typography>
                      <Typography
                        className="dateMeetConfirmation"
                        sx={{ color: '#FA3E6A', fontSize: '18px', fontWeight: 600 }}
                      >
                        {meetingDay
                          ? new Date(meetingDay.$d).toLocaleDateString('es-AR', {
                            month: 'long',
                            day: 'numeric'
                          })
                          : '-'}
                        , {timeSelected ? `${timeSelected} hs` : '-'}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'space-between',
                        height: '95px !important'
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Icon
                          sx={{
                            backgroundColor: '#FAFAFA',
                            color: '#606161',
                            width: '40px',
                            height: '40px',
                            borderRadius: '6px',
                            marginRight: '8px',
                            paddingTop: '5px',
                            fontSize: '1.8rem !important'
                          }}
                        >
                          access_time
                        </Icon>
                        <Typography sx={{ fontSize: '1.2rem', color: '#606161' }}>
                          {availabilityTime?.data.duration} min de duración
                        </Typography>
                      </Box>
                      {availabilityTime && availabilityTime.data.conference ? (
                        availabilityTime.data.Calendar.provider === 'google-oauth2' ? (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box
                              sx={{
                                backgroundColor: '#FAFAFA',
                                width: '40px',
                                height: '40px',
                                borderRadius: '6px',
                                fontSize: '1.8rem !important',
                                display: 'flex',
                                marginRight: '8px'
                              }}
                            >
                              <IconTV sx={{ margin: 'auto' }} src={live_tv} />
                            </Box>
                            <Typography sx={{ fontSize: '1.2rem', color: '#606161' }}>
                              Google Meet
                            </Typography>
                          </Box>
                        ) : (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box
                              sx={{
                                backgroundColor: '#FAFAFA',
                                width: '40px',
                                height: '40px',
                                borderRadius: '6px',
                                fontSize: '1.8rem !important',
                                display: 'flex',
                                marginRight: '8px'
                              }}
                            >
                              <IconTV sx={{ margin: 'auto' }} src={live_tv} />
                            </Box>
                            <Typography sx={{ fontSize: '1.2rem', color: '#606161' }}>
                              /Microsoft Teams/Skype
                            </Typography>
                          </Box>
                        )
                      ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Icon
                            sx={{
                              backgroundColor: '#FAFAFA',
                              color: '#606161',
                              width: '40px',
                              height: '40px',
                              borderRadius: '6px',
                              marginRight: '8px',
                              paddingTop: '5px',
                              fontSize: '1.8rem !important'
                            }}
                          >
                            room
                          </Icon>
                          <Typography
                            sx={{ fontSize: '1.2rem', color: '#606161', textTransform: 'capitalize' }}
                          >
                            {availabilityTime?.data.location
                              ? availabilityTime.data.location
                              : 'A convenir'}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
                {meetingConfirmation ? (
                  <>
                    <Box
                      className="boxTypoMeetConfirm"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: '#F1FFF4',
                        padding: '1.5rem 6rem',
                        borderRadius: '6px',
                        width: '100%',
                        marginTop: '.5rem'
                      }}
                    >
                      <TaskAlt src={iconCheck} />
                      <Typography
                        sx={{
                          color: '#048C1A',
                          textAlign: 'center',
                          fontSize: '1rem',
                          fontWeight: '600'
                        }}
                      >
                        Reunión agendada correctamente.
                      </Typography>
                    </Box>
                    <Box
                      className="boxTypoMeetConfirm"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        padding: '1.5rem 6rem'
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#757575',
                          textAlign: 'center',
                          fontSize: '18px',
                          fontWeight: '500'
                        }}
                      >
                        Redireccionando a my.patras.io en {countDown}s
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <TextField
                      sx={{ width: '100%' }}
                      margin="dense"
                      id="name"
                      label="Tu correo electrónico"
                      type="text"
                      fullWidth
                      value={guestEmail && guestEmail}
                      onChange={(e) => setGuestEmail(e.target.value)}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        margin: '1.4rem 0 0'
                      }}
                    >
                      <Box sx={{ width: '50px', height: '50px' }}></Box>
                      <Button
                        sx={{
                          backgroundColor: '#FFF1F4',
                          padding: '.8rem',
                          fontWeight: '500',
                          '&:hover': {
                            backgroundColor: '#FFF1F4 !important'
                          }
                        }}
                        onClick={meetingCreated}
                        color="primary"
                      >
                        {loading && (
                          <CircularProgress size={20} sx={{ marginRight: '10px', color: '#FA3E6A' }} />
                        )}
                        Confirmar
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            </Dialog>
          </Box>
          :
          <Box className="publicCalendarShared-container">
            <Box className="mainDate-container">
              <Typography className="titleCalendar">Programar una reunión</Typography>
              <Box className="dataPicker-container">
                <Box className="dateSelection-container">
                  <Box className="box-calendar">
                    <Typography className="titleInstruction">Elegir el día</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        label="Week picker"
                        value={value}
                        onChange={(newValue) => {
                          handleDateChoice(newValue);
                        }}
                        renderDay={renderWeekPickerDay}
                        renderInput={(params) => <TextField {...params} />}
                        inputFormat="'Week of' MMM d"
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box className="box-times">
                    <Typography className="titleInstruction">Elegir el horario</Typography>
                    <FormControl
                      sx={{ backgroundColor: '', width: '90%' }}
                      component="fieldset"
                      className="formControl"
                    >
                      <RadioGroup
                        name="emailService"
                        className="group"
                        aria-label="Email"
                        sx={{ backgroundColor: '' }}
                        onChange={(e) => handleTimeChoice(e.target.value)}
                      >
                        {availableTime.length !== 0 &&
                          availableTime?.map((time, index) =>
                            timeSelected !== time ? (
                              <FormControlLabel
                                sx={{
                                  backgroundColor: '#FAFAFA',
                                  width: '100%',
                                  justifyContent: 'space-between',
                                  padding: '.5rem .8rem',
                                  margin: '.3rem'
                                }}
                                labelPlacement="start"
                                value={time}
                                control={<Radio />}
                                label={time}
                                key={index}
                              />
                            ) : (
                              <FormControlLabel
                                sx={{
                                  backgroundColor: '#FFF1F4',
                                  opacity: '100%',
                                  width: '100%',
                                  justifyContent: 'space-between',
                                  padding: '.5rem .8rem',
                                  margin: '.3rem'
                                }}
                                labelPlacement="start"
                                value={time}
                                control={
                                  <Radio
                                    sx={{
                                      color: '#f7d2d2',
                                      '&.Mui-checked': {
                                        color: '#C23750'
                                      }
                                    }}
                                  />
                                }
                                label={time}
                                key={index}
                              />
                            )
                          )}
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>
                <Box className="infoMeet-container">
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                      justifyContent: 'space-around',
                      height: '40%'
                    }}
                  >
                    <Typography className="infoName" sx={{ fontSize: '1rem' }}>
                      {availabilityTime?.data.ownerName}
                    </Typography>
                    <Typography
                      className="infoNameMeet"
                      sx={{ fontSize: '2rem', marginTop: '10px', fontWeight: '500' }}
                    >
                      {availabilityTime?.data.eventName ? availabilityTime?.data.eventName : 'Meeting'}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                      <Icon
                        sx={{
                          backgroundColor: '#FAFAFA',
                          color: '#606161',
                          width: '40px',
                          height: '40px',
                          borderRadius: '6px',
                          marginRight: '8px',
                          paddingTop: '5px',
                          fontSize: '1.8rem !important'
                        }}
                      >
                        access_time
                      </Icon>
                      <Typography className="subInfoMeet" sx={{ fontSize: '1.2rem', color: '#606161' }}>
                        {availabilityTime?.data.duration} min de duración
                      </Typography>
                    </Box>
                    {availabilityTime && availabilityTime.data.conference ? (
                      availabilityTime.data.Calendar.provider === 'google-oauth2' ? (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box
                            sx={{
                              backgroundColor: '#FAFAFA',
                              width: '40px',
                              height: '40px',
                              borderRadius: '6px',
                              marginRight: '8px',
                              fontSize: '1.8rem !important'
                            }}
                          >
                            <IconTV sx={{ margin: 'auto' }} src={live_tv} />
                          </Box>
                          <Typography sx={{ fontSize: '1.2rem', color: '#606161' }}>
                            Google Meet
                          </Typography>
                        </Box>
                      ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box
                            sx={{
                              backgroundColor: '#FAFAFA',
                              width: '40px',
                              height: '40px',
                              borderRadius: '6px',
                              marginRight: '8px',
                              fontSize: '1.8rem !important'
                            }}
                          >
                            <IconTV sx={{ margin: 'auto' }} src={live_tv} />
                          </Box>
                          <Typography sx={{ fontSize: '1.2rem', color: '#606161' }}>
                            /Microsoft Teams/Skype
                          </Typography>
                        </Box>
                      )
                    ) : (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Icon
                          sx={{
                            backgroundColor: '#FAFAFA',
                            color: '#606161',
                            width: '40px',
                            height: '40px',
                            borderRadius: '6px',
                            marginRight: '8px',
                            paddingTop: '5px',
                            fontSize: '1.8rem !important'
                          }}
                        >
                          room
                        </Icon>
                        <Typography sx={{ fontSize: '1.2rem', color: '#606161' }}>
                          {availabilityTime?.data.location
                            ? availabilityTime.data.location
                            : 'A convenir'}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  {meetingDay && timeSelected && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                      <Typography className="titleDateSelected">Fecha seleccionada</Typography>
                      <Typography
                        className="infoDateSelected"
                        sx={{ color: '#FA3E6A', fontSize: '22px', fontWeight: 600 }}
                      >
                        {new Date(meetingDay.$d).toLocaleDateString('es-AR', {
                          month: 'long',
                          day: 'numeric'
                        })}
                        , {timeSelected} hs
                      </Typography>
                    </Box>
                  )}
                  {meetingDay && timeSelected ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: '#C23750',
                        padding: '10px 25px',
                        borderRadius: '4px',
                        cursor: 'pointer'
                      }}
                    >
                      <Icon sx={{ color: 'white', marginRight: '8px' }}>date_range</Icon>
                      <Typography
                        sx={{ textTransform: 'uppercase', fontSize: '14px', color: 'white' }}
                        onClick={handleConfirmation}
                      >
                        Guardar reunión
                      </Typography>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: '#F5F5F5',
                        padding: '10px 25px',
                        borderRadius: '4px'
                      }}
                    >
                      <Icon sx={{ color: '#BEBDBD', marginRight: '8px' }}>date_range</Icon>
                      <Typography
                        sx={{ textTransform: 'uppercase', fontSize: '14px', color: '#BEBDBD' }}
                        onClick={handleConfirmation}
                      >
                        Guardar reunión
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            <Dialog
              open={modalEmail}
              onClose={() => {
                setModalEmail(false);
              }}
              aria-labelledby="form-dialog-title"
              sx={{ ...style }}
            >
              <Box
                className="meetConfirmation-container"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                  justifyContent: 'space-between',
                  padding: '26px',
                  height: '25rem',
                  width: '35rem'
                }}
              >
                {meetingConfirmation ? (
                  <Typography
                    sx={{ fontSize: '22px', fontWeight: '600', marginBottom: '1rem' }}
                    id="form-dialog-title"
                  >
                    Reunión confirmada
                  </Typography>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                      marginBottom: '.5rem'
                    }}
                  >
                    <Typography sx={{ fontSize: '22px', fontWeight: '600' }} id="form-dialog-title">
                      ¿Confirmar reunión?
                    </Typography>
                    <Icon
                      onClick={() => setModalEmail(false)}
                      sx={{ fontWeight: '600 !important', cursor: 'pointer' }}
                    >
                      close
                    </Icon>
                  </Box>
                )}
                <Box sx={{ width: '100%' }}>
                  <Box
                    className="infoMeetConfirmation"
                    sx={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between' }}
                  >
                    <Box
                      sx={{
                        gap: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Typography sx={{ fontSize: '15px', color: '#606161' }}>
                        {availabilityTime?.data.ownerName}
                      </Typography>
                      <Typography sx={{ fontSize: '22px', fontWeight: '600' }}>
                        {availabilityTime?.data.eventName
                          ? availabilityTime?.data.eventName
                          : 'Meeting'}
                      </Typography>
                      <Typography
                        className="dateMeetConfirmation"
                        sx={{ color: '#FA3E6A', fontSize: '18px', fontWeight: 600 }}
                      >
                        {meetingDay
                          ? new Date(meetingDay.$d).toLocaleDateString('es-AR', {
                            month: 'long',
                            day: 'numeric'
                          })
                          : '-'}
                        , {timeSelected ? `${timeSelected} hs` : '-'}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'space-between',
                        height: '95px !important'
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Icon
                          sx={{
                            backgroundColor: '#FAFAFA',
                            color: '#606161',
                            width: '40px',
                            height: '40px',
                            borderRadius: '6px',
                            marginRight: '8px',
                            paddingTop: '5px',
                            fontSize: '1.8rem !important'
                          }}
                        >
                          access_time
                        </Icon>
                        <Typography sx={{ fontSize: '1.2rem', color: '#606161' }}>
                          {availabilityTime?.data.duration} min de duración
                        </Typography>
                      </Box>
                      {availabilityTime && availabilityTime.data.conference ? (
                        availabilityTime.data.Calendar.provider === 'google-oauth2' ? (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box
                              sx={{
                                backgroundColor: '#FAFAFA',
                                width: '40px',
                                height: '40px',
                                borderRadius: '6px',
                                fontSize: '1.8rem !important',
                                display: 'flex',
                                marginRight: '8px'
                              }}
                            >
                              <IconTV sx={{ margin: 'auto' }} src={live_tv} />
                            </Box>
                            <Typography sx={{ fontSize: '1.2rem', color: '#606161' }}>
                              Google Meet
                            </Typography>
                          </Box>
                        ) : (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box
                              sx={{
                                backgroundColor: '#FAFAFA',
                                width: '40px',
                                height: '40px',
                                borderRadius: '6px',
                                fontSize: '1.8rem !important',
                                display: 'flex',
                                marginRight: '8px'
                              }}
                            >
                              <IconTV sx={{ margin: 'auto' }} src={live_tv} />
                            </Box>
                            <Typography sx={{ fontSize: '1.2rem', color: '#606161' }}>
                              /Microsoft Teams/Skype
                            </Typography>
                          </Box>
                        )
                      ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Icon
                            sx={{
                              backgroundColor: '#FAFAFA',
                              color: '#606161',
                              width: '40px',
                              height: '40px',
                              borderRadius: '6px',
                              marginRight: '8px',
                              paddingTop: '5px',
                              fontSize: '1.8rem !important'
                            }}
                          >
                            room
                          </Icon>
                          <Typography
                            sx={{ fontSize: '1.2rem', color: '#606161', textTransform: 'capitalize' }}
                          >
                            {availabilityTime?.data.location
                              ? availabilityTime.data.location
                              : 'A convenir'}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
                {meetingConfirmation ? (
                  <>
                    <Box
                      className="boxTypoMeetConfirm"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: '#F1FFF4',
                        padding: '1.5rem 6rem',
                        borderRadius: '6px',
                        width: '100%',
                        marginTop: '.5rem'
                      }}
                    >
                      <TaskAlt src={iconCheck} />
                      <Typography
                        sx={{
                          color: '#048C1A',
                          textAlign: 'center',
                          fontSize: '1rem',
                          fontWeight: '600'
                        }}
                      >
                        Reunión agendada correctamente.
                      </Typography>
                    </Box>
                    <Box
                      className="boxTypoMeetConfirm"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        padding: '1.5rem 6rem'
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#757575',
                          textAlign: 'center',
                          fontSize: '18px',
                          fontWeight: '500'
                        }}
                      >
                        Redireccionando a my.patras.io en {countDown}s
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <TextField
                      sx={{ width: '100%' }}
                      margin="dense"
                      id="name"
                      label="Tu correo electrónico"
                      type="text"
                      fullWidth
                      value={guestEmail && guestEmail}
                      onChange={(e) => setGuestEmail(e.target.value)}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        margin: '1.4rem 0 0'
                      }}
                    >
                      <Box sx={{ width: '50px', height: '50px' }}></Box>
                      <Button
                        sx={{
                          backgroundColor: '#FFF1F4',
                          padding: '.8rem',
                          fontWeight: '500',
                          '&:hover': {
                            backgroundColor: '#FFF1F4 !important'
                          }
                        }}
                        onClick={meetingCreated}
                        color="primary"
                      >
                        {loading && (
                          <CircularProgress size={20} sx={{ marginRight: '10px', color: '#FA3E6A' }} />
                        )}
                        Confirmar
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            </Dialog>
          </Box>
      }
      {(onlyWidth > 1000 && searchMatchAv && loggedUser) &&
        <PrimaryButton sx={{ alignSelf: 'flex-end', mr: 21, mt: 18 }} onClick={() => handleAutomaticSearch()}>
          <FlexJustifyCenter sx={{ mr: .5 }}><CalendarMonthIcon height='18px' width='16px' color='white' /></FlexJustifyCenter> Buscar automaticamente
        </PrimaryButton>}
      <Image className="logo" src={logo} />
    </Box>
  );
}

export default PublicCalendarShared;
